import Layout from "../../components/layout/layout";
import SubPageTopBanner from "../../components/SubPageTopBanner/SubPageTopBanner";
import ContactForm from "./contactForm/contactForm";
import Map from "./map/map";
import { Contact_Content } from "../../utils/text_content";
const ContactUs = () => {
  return (
    <>
      <div className="contact_us_page_background">
        <Layout>
          <SubPageTopBanner title={Contact_Content?.CONTACT_US} />
          <Map/>
          <ContactForm/>
        </Layout>
      </div>
    </>
  );
};
export default ContactUs;
