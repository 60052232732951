import EmailIcon from "../../../assets/svg/square-email-white.svg";
import { Banner_Content, Button_Text } from "../../../utils/text_content";
import { useNavigate } from "react-router-dom";
const Banner = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <div className="custom-container h-[90vh] flex justify-center">
          <div className="flex flex-col justify-center items-center">
            <div className="text-[25px] text-[#fff] lg:text-[30px] lg:leading-[40px] uppercase font-light text-center mx-auto">
              {Banner_Content?.WELCOME_HEAVY_EQUIPMENT}
            </div>
            <h2 className="text-[40px] text-[#edac15] font-bold leading-[45px] xl:text-[50px] xl:leading-[50px] 2xl:text-[70px] 2xl:leading-[65px] text-center">
              {Banner_Content?.PRE_OWNED_CONSTRUCTION}
            </h2>
            <h2 className="text-[40px] text-[#edac15] font-bold leading-[45px] xl:text-[50px] xl:leading-[50px] 2xl:text-[70px] 2xl:leading-[65px] text-center">
              {Banner_Content?.EQUIPMENT}
            </h2>
            <h1 className="text-[18px] text-[#fff] leading-[25px] font-bold">
              {Banner_Content?.CHECK_INVENTORY}
            </h1>
            <button className="bg-white hover:bg-[#4f4f4f] text-black hover:text-white my-3 py-2 px-4 rounded text-[15px] w-full lg:w-auto"  onClick={()=>navigate('/inventory')}>
              {Button_Text?.FOR_SALE}
            </button>
          </div>
        </div>
        <div className="bg-[#212121] py-[25px]">
          <div className="w-[80%] lg:w-[80%] xl:w-[60%] m-auto flex flex-col lg:flex-row justify-center">
            <div className="w-[100%] lg:w-[70%] flex items-center flex-col lg:items-start">
              <div className="text-[22px] text-[#edac15]  font-medium text-center lg:text-start">
                {Banner_Content?.CONSTRUCTION_EQUIPMENT}
              </div>
              <div className="text-[22px] text-[#edac15]  font-medium text-center lg:text-start">
                {Banner_Content?.LOOKING_SELL}
              </div>
              <div className="text-[16px] text-[#fff]  font-medium text-center lg:text-start">
                {Banner_Content?.INTERESTED_IN_BUYING}
              </div>
            </div>
            <div className="w-[100%] lg:w-[30%] flex items-center flex-col lg:items-start">
              <button className="bg-[#4D4D4D] hover:bg-[#edac15] text-white my-3 py-2 px-4 rounded text-[15px] tracking-[1px] flex items-center px-8 py-4" onClick={()=>navigate('/contact')}>
                <img src={EmailIcon} className="w-[13px] mr-2" alt="email" />
                {Button_Text?.CONTACT_US}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Banner;
