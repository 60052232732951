import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {getInventory} from '../../../services/machineService'
import MachineImg from '../../../assets/Img/img.jpg'
import {
  Inventory_Notice,
  MachineList_Content,
} from '../../../utils/text_content'
import {ApplicationId} from '../../../utils/text_content'
import MagnifyingGlass from '../../../assets/svg/magnifying-glass.svg'
import AngleRightSolid from '../../../assets/svg/angle-right-solid.svg'
import ContactUsModel from '../../../components/Model/ContactUsModel/ContactUsModel'
import PrintIcon from '../../../assets/svg/print-solid.svg'
import shareIcon from '../../../assets/svg/share-nodes-solid.svg'
import {getAllMachinesList} from '../../../services/machineService'
const MachineCard = ({item, showModal, setShowModal, setSelectCardDetails}) => {
  const navigate = useNavigate()

  const handlePriceValue = item => {
    const handleOnPressCall = () => {
      if (item?.dealer?.contact_user?.phone) {
        return `callto:${item?.dealer?.contact_user?.phone}`
      } else if (item?.dealer?.contact_user?.email) {
        return `mailto:${item?.dealer?.contact_user?.email}`
      } else {
        return 'javascript:void(0)'
      }
    }
    if (item?.advertised_price?.fractional != '0') {
      return `${item?.advertised_price?.fractional} ${item?.advertised_price?.currency?.symbol}`
    } else {
      return (
        <a href={handleOnPressCall()} className="text-[13px] font-bold">
          Call for Price
        </a>
      )
    }
  }
  return (
    <>
      <div className="border-solid border border-[#dddddd] p-[15px]">
        <div className="flex flex-col sm:flex-row">
          <div className="w-full sm:w-[50%]">
            <div className="bg-[#333333] py-10 flex items-center justify-center">
              <img src={item?.primary_image?.url} alt="" />
            </div>
          </div>
          <div className="w-full sm:w-[50%] px-3">
            <h1 className="text-[15px] font-bold tracking-[1px]">
              {item?.year} {item?.manufacturer?.name} {item?.model?.number}
            </h1>
            <div className="text-[13px]">{item?.Type}</div>
            <div>
              <span className="text-[13px] font-bold">Price:</span>
              <span className="text-[16px] text-[#3a658e] font-bold ml-3">
                {/* {item?.advertised_price?.fractional} */}
                {handlePriceValue(item)}
              </span>
            </div>
            <div>
              <div className="text-[13px] font-bold">Machine Location:</div>
              <div className="text-[13px]">{item?.dealer?.location}</div>
            </div>
            <div className="mt-3">
              <div className="flex">
                <div className="text-[13px] font-bold">Hours :</div>
                <div className="ml-3 text-[13px]">{item?.meter_reading}</div>
              </div>
              {/* <div className="flex">
                <div className="text-[13px] font-bold">Drive:</div>
                <div className="ml-3 text-[13px]">{item?.Drive}</div>
              </div> */}
              <div className="flex">
                <div className="text-[13px] font-bold">Condition:</div>
                <div className="ml-3 text-[13px]">
                  {item?.meter_reading ? (
                    <label>Used</label>
                  ) : (
                    <label>New</label>
                  )}
                </div>
              </div>
              {/* <div className="flex">
                <div className="text-[13px] font-bold">
                  Back Auxiliary Hydraulics:
                </div>
                <div className="ml-3 text-[13px]">
                  {item?.BackAuxiliaryHydraulics}
                </div>
              </div> */}
            </div>

            <div className="w-full">
              <button
                className="bg-[#204682] text-white text-[16px] w-full p-[10px] rounded flex items-center justify-center"
                onClick={() => {
                  const encodeURICom = encodeURIComponent(
                    `${item.assetAdvertisementId}-${item?.year}-${item?.manufacturer?.name}-${item?.model?.number}`,
                  )
                  navigate(`/inventory-details/${encodeURICom}`, {
                    state: {id: item?.assetAdvertisementId},
                  })
                }}
              >
                <span>
                  <img src={MagnifyingGlass} alt="" className="w-[16px] mr-2" />
                </span>
                View Details
              </button>
            </div>
            <div className=" w-full mt-2">
              <button
                className="bg-[#204682] text-white text-[12px] w-full p-[10px] rounded flex relative justify-center items-center"
                onClick={() => {
                  setSelectCardDetails(
                    `${item?.year} ${item?.manufacturer?.name} ${item?.model?.number}`,
                  )
                  setShowModal(!showModal)
                }}
              >
                Contact Us
                <span className="relative ml-3 sm:ml-0 sm:absolute sm:right-0 sm:mr-2">
                  <img src={AngleRightSolid} className="w-[10px]" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const MachineList = () => {
  const [showModal, setShowModal] = useState(false)
  const [selectCardDetails, setSelectCardDetails] = useState('')
  const [page, setPage] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalRecords, setTotalRecords] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [inventoryList, setInventoryList] = useState([])
  const [machinesTypeList, setMachinesTypeList] = useState([])
  const [allFilterID, setAllFilterID] = useState(null)
  const [machineTypeSelectIdCategoryName, setMachineTypeSelectIdCategoryName] =
    useState('')
  const [assetTypeId, setAssetTypeId] = useState('')
  const limit = 6

  useEffect(() => {
    getData()
  }, [
    pageNumber,
    machinesTypeList,
    allFilterID,
    machineTypeSelectIdCategoryName,
  ])

  useEffect(() => {
    GetMachinesTypeList()
  }, [])

  const GetMachinesTypeList = val => {
    setIsLoading(true)
    getAllMachinesList()
      .then(res => {
        const data = res?.data
        setMachinesTypeList(data)
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  const getData = () => {
    setIsLoading(true)
    let payload

    if (allFilterID) {
      payload = {
        ApplicationId: ApplicationId,
        AssetTypeId: JSON.parse(assetTypeId),
        PageSize: limit,
        PageNumber: pageNumber,
        Tier1Id: JSON.parse(allFilterID[2]),
        Tier2Id: JSON.parse(allFilterID[3]),
        Tier3Id: JSON.parse(allFilterID[4]),
        CategoryId: JSON.parse(allFilterID[5]),
      }
    } else {
      payload = {
        ApplicationId: ApplicationId,
        AssetTypeId: assetTypeId,
        PageSize: limit,
        PageNumber: pageNumber,
      }
    }
    getInventory(payload)
      .then(res => {
        setPage(res?.data?.PageNumber)
        setTotalRecords(res?.data?.TotalRecords)
        setInventoryList(res?.data?.machines)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
      })
  }

  const handleOnChangeType = e => {
    // ------------------ Split zero CategoryName ------------------
    // ------------------ Split One AssetTypeId --------------------
    // ------------------ Split two Tier1Id --------------------
    // ------------------ Split three Tier2Id --------------------
    // ------------------ Split four Tier3Id ------------------
    // ------------------ Split five CategoryId ------------------
    if (e.target.value != '') {
      const IDs = e.target.value.split(',')
      setPageNumber(1)
      setAllFilterID(IDs)
      setMachineTypeSelectIdCategoryName(IDs[0])
      setAssetTypeId(IDs[1])
    } else {
      setAllFilterID(null)
      setMachineTypeSelectIdCategoryName('')
      setAssetTypeId('')
    }
  }

  return (
    <>
      {showModal && (
        <ContactUsModel setShowModal={setShowModal} name={selectCardDetails} />
      )}
      <div className="bg-white">
        <div className="custom-container py-[25px] lg:py-[70px]">
          <div className="text-[28px] font-bold tracking-[2px] flex items-center justify-between xl:max-w-[85%] m-auto mb-5 flex-wrap">
            {MachineList_Content?.EQUIPMENT_SALE}
            <div className="flex mt-2 sm:mt-0">
              <button
                className="bg-[#F3F3F3] text-[#555555] font-bold text-[14px] px-[30px] py-[6px] border border-[silver] rounded flex justify-center items-center"
                onClick={() => {
                  window.print()
                }}
              >
                <img src={PrintIcon} className="w-[14px] mr-1" /> Print
              </button>
              <button
                className="bg-[#F3F3F3] text-[#555555] font-bold text-[14px] px-[30px] py-[6px] border border-[silver] rounded flex justify-center items-center ml-2"
                onClick={() => {
                  setShowModal(!showModal)
                }}
              >
                <img src={shareIcon} className="w-[14px] mr-1" /> Share
              </button>
            </div>
          </div>
          <div className="flex flex-start xl:max-w-[85%] m-auto pb-5  flex-wrap">
            <select
              name="cat"
              id="category-dropdown"
              className="border border-[#dddddd] w-full sm:w-auto"
              onChange={handleOnChangeType}
            >
              <option value={''}>Select All</option>
              {machinesTypeList?.map((item, index) => {
                return item?.CategoryDetails?.length > 0 ? (
                  <>
                    <option
                      value={`${null},${
                        item?.AssetTypeId
                      },${null},${null},${null},${null}`}
                    >
                      {item?.AssetTypeName}
                    </option>
                    {item?.CategoryDetails.map(CategoryDetailsitem => {
                      return CategoryDetailsitem?.Tier1Details?.length > 0 ? (
                        <>
                          <option
                            value={`${CategoryDetailsitem?.CategoryName},${
                              item?.AssetTypeId
                            },${null},${null},${null},${
                              CategoryDetailsitem?.CategoryId
                            }`}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {CategoryDetailsitem?.CategoryName}
                          </option>
                          {CategoryDetailsitem?.Tier1Details?.map(
                            Tier1DetailsItem => {
                              return Tier1DetailsItem?.Tier2Details?.length >
                                0 ? (
                                <>
                                  <option
                                    value={`${
                                      CategoryDetailsitem?.CategoryName
                                    },${item?.AssetTypeId},${
                                      Tier1DetailsItem?.Tier1Id
                                    },${null},${null},${
                                      CategoryDetailsitem?.CategoryId
                                    }`}
                                  >
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {Tier1DetailsItem?.Tier1Name}
                                  </option>
                                  {Tier1DetailsItem?.Tier2Details?.map(
                                    Tier2DetailsItem => {
                                      return Tier2DetailsItem?.Tier3Details
                                        ?.length > 0 ? (
                                        <>
                                          <option
                                            value={`${
                                              CategoryDetailsitem?.CategoryName
                                            },${item?.AssetTypeId},${
                                              Tier1DetailsItem?.Tier1Id
                                            },${
                                              Tier2DetailsItem?.Tier2Id
                                            },${null},${
                                              CategoryDetailsitem?.CategoryId
                                            }`}
                                          >
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            {Tier2DetailsItem?.Tier2Name}
                                          </option>
                                          {Tier2DetailsItem?.Tier3Details?.map(
                                            Tier3Detailsitem => {
                                              return Tier3Detailsitem
                                                ?.Tier4Details?.length > 0 ? (
                                                <option
                                                  value={`${CategoryDetailsitem?.CategoryName},${item?.AssetTypeId},${Tier1DetailsItem?.Tier1Id},${Tier2DetailsItem?.Tier2Id},${Tier3Detailsitem?.Tier3Id},${CategoryDetailsitem?.CategoryId}`}
                                                >
                                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                  {Tier3Detailsitem?.Tier3Name}
                                                </option>
                                              ) : (
                                                <option
                                                  value={`${CategoryDetailsitem?.CategoryName},${item?.AssetTypeId},${Tier1DetailsItem?.Tier1Id},${Tier2DetailsItem?.Tier2Id},${Tier3Detailsitem?.Tier3Id},${CategoryDetailsitem?.CategoryId}`}
                                                >
                                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                  {Tier3Detailsitem?.Tier3Name}
                                                </option>
                                              )
                                            },
                                          )}
                                        </>
                                      ) : (
                                        <option
                                          value={`${
                                            CategoryDetailsitem?.CategoryName
                                          },${item?.AssetTypeId},${
                                            Tier1DetailsItem?.Tier1Id
                                          },${
                                            Tier2DetailsItem?.Tier2Id
                                          },${null},${
                                            CategoryDetailsitem?.CategoryId
                                          }`}
                                        >
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          {Tier2DetailsItem?.Tier2Name}
                                        </option>
                                      )
                                    },
                                  )}
                                </>
                              ) : (
                                <option
                                  value={`${
                                    CategoryDetailsitem?.CategoryName
                                  },${item?.AssetTypeId},${
                                    Tier1DetailsItem?.Tier1Id
                                  },${null},${null},${
                                    CategoryDetailsitem?.CategoryId
                                  }`}
                                >
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  {Tier1DetailsItem?.Tier1Name}
                                </option>
                              )
                            },
                          )}
                        </>
                      ) : (
                        <option
                          value={`${CategoryDetailsitem?.CategoryName},${
                            item?.AssetTypeId
                          },${null},${null},${null},${
                            CategoryDetailsitem?.CategoryId
                          }`}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {CategoryDetailsitem?.CategoryName}
                        </option>
                      )
                    })}
                  </>
                ) : (
                  <option
                    value={`${null},${
                      item?.AssetTypeId
                    },${null},${null},${null},${null}`}
                  >
                    {item?.AssetTypeName}
                  </option>
                )
              })}
            </select>
          </div>

          {isLoading ? (
            <div
              className=" flex justify-center items-center"
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                marginTop: '30px',
                height: '100vh',
              }}
            >
              <div className="spinner-container">
                <div className="loading-spinner"></div>
              </div>
            </div>
          ) : inventoryList.length > 0 ? (
            <>
              <div className="pb-[20px]">
                <div class="grid lg:grid-cols-1 xl:grid-cols-2 grid-flow-row gap-6 w-full xl:max-w-[85%] m-auto border-solid border border-[#dddddd] p-[15px]">
                  {inventoryList?.map((item, index) => {
                    return (
                      <MachineCard
                        item={item}
                        setShowModal={setShowModal}
                        showModal={showModal}
                        setSelectCardDetails={setSelectCardDetails}
                      />
                    )
                  })}
                </div>

                {/* ----------Paginate Button ---------- */}

                <div className="flex justify-center mt-10">
                  {page > 1 && (
                    <button
                      className="mr-2 font-bold"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        })
                        setPageNumber(page - 1)
                      }}
                    >
                      {'<<Previous'}
                    </button>
                  )}
                  {Array(Math.ceil(totalRecords / limit))
                    .fill(0)
                    .map((_, index) => {
                      return (
                        <button
                          className={
                            page === index + 1
                              ? 'bg-black font-bold text-white mx-2 p-2'
                              : 'mx-2 font-bold'
                          }
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              behavior: 'smooth',
                            })
                            setPageNumber(index + 1)
                          }}
                        >
                          {index + 1}
                        </button>
                      )
                    })}
                  {page < Math.ceil(totalRecords / limit) && (
                    <button
                      className="ml-2 font-bold"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        })
                        setPageNumber(page + 1)
                      }}
                    >
                      {'next>>'}
                    </button>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className=" flex justify-center items-center"
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  marginTop: '30px',
                  height: '80vh',
                }}
              >
                <div className="spinner-container text-[30px] text-[#000]">
                  No Data Found
                </div>
              </div>
            </>
          )}

          <div className="my-10 text-[16px]">{Inventory_Notice?.Notice}</div>
        </div>
      </div>
    </>
  )
}
export default MachineList
