const FormInput = ({
  title,
  values,
  errors,
  handleChange,
  touched,
  id,
  name,
  type,
  placeholder,
}) => {
  return (
    <>
      <div className="mb-1 flex flex-col">
        <label className="text-white text-[16px] py-2 text-center">
         {title}<span className="text-[#FF0000] pl-1">*</span>
        </label>
        <input
          type={type}
          id={id}
          name={name}
          className="form-control p-2"
          value={values?.[`${name}`]}
          onChange={(e) => handleChange(e)}
        />

        {errors?.[`${name}`] && touched?.[`${name}`] && (
          <div
            style={{ fontSize: 14, textAlign: "left" }}
            className=" mt-1 text-red-600"
          >
            {errors?.[`${name}`]}
          </div>
        )}
      </div>
    </>
  );
};
export default FormInput;
