import Cat_Machine from "../../../assets/Img/Cat_Machine.jpg";
import {
  Review_Content,
  Review_Carousel_List,
} from "../../../utils/text_content";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const Review = () => {
  return (
    <>
      <div className="review_bg">
        <div className="custom-container flex justify-end py-[20px] lg:py-[0px]">
          <div className="max-w-[50%] hidden lg:block">
            <div className="bg-white p-5">
              <img src={Cat_Machine} alt="cat machine" />
            </div>
          </div>
          <div className="max-w-full lg:max-w-[50%] flex justify-center flex-col px-5">
            <div className="text-center text-[#fff] text-[25px] ">
              <h1>{Review_Content?.WHAT_CUSTOMERS_SAY}</h1>
            </div>
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              showThumbs={false}
              centerMode={false}
              showStatus={false}
              axis="horizontal"
            >
              {Review_Carousel_List?.map((item, index) => {
                return (
                  <div>
                    <h2 className="text-[#edac15] text-[16px] my-[15px] font-normal">
                      {item?.name}
                    </h2>
                    <div className="text-[16px] md:text-[1.2em] text-[#fff] mb-[35px] leading-[24px] tracking-[0.4px] font-light px-5 lg:px-20">
                      {item?.text}
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};
export default Review;
