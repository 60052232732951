import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Layout from '../../components/layout/layout'
import MachineImg from '../../assets/Img/img.jpg'
import ImageGallery from 'react-image-gallery'
import {Inventory_Notice} from '../../utils/text_content'
import ContactUsModel from '../../components/Model/ContactUsModel/ContactUsModel'
import {useLocation} from 'react-router-dom'
import PrintIcon from '../../assets/svg/print-solid.svg'
import shareIcon from '../../assets/svg/share-nodes-solid.svg'
import phoneFlipIcon from '../../assets/svg/phone-flip-solid.svg'
import emailFlipIcon from '../../assets/svg/envelope-solid.svg'
import externalFlipIcon from '../../assets/svg/arrow-up-right.svg'
import {getMachineDetailsByID} from '../../services/machineService'
import {getUniqueListBy} from '../../utils/helper'
import {InventoryDetail_Content} from '../../utils/text_content'
import EmailIcone from '../../assets/svg/email-icone.svg'
import './style.css'

const InventoryDetails = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const location = useLocation()
  const [showModal, setShowModal] = useState(false)
  const [images, setImages] = useState()
  const [carouselImg, setCarouselImg] = useState([])
  const machine_general_html = `
  <html>
  <style></style>
  <body>
    <h1 style="font-size:20px; font-weight:bold;">General</h1>

    <table style="width:100%">
      <tr>
        <td>Year</td>
        <td style="font-weight:300;">${data?.year}</td>
      </tr>
      <tr>
        <td>Manufacturer</td>
        <td style="font-weight:300;">${data?.manufacturer?.name}</td>
      </tr>
      <tr>
        <td>Model</td>
        <td style="font-weight:300;">${data?.model?.number}</td>
      </tr>
      <tr>
        <td>Hours</td>
        <td style="font-weight:300;">${data?.meter_reading}</td>
      </tr>
      <tr>
        <td>Condition</td>
        <td style="font-weight:300;">${
          data?.meter_reading ? 'Used' : 'New'
        }</td>
      </tr>
    </table>
  </body>
</html>
  `

  const getMachineDetails = () => {
    setIsLoading(true)
    const id = location?.pathname?.split('/')[2]?.split('-')[0]
    getMachineDetailsByID(id)
      .then(res => {
        setData(res?.data)
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (data) {
      let imageData = data?.images?.map(({url: image, ...rest}) => ({
        image,
        ...rest,
      }))
      setImages(imageData)
    }
  }, [data])
  useEffect(() => {
    if (images) {
      let uniqueData = getUniqueListBy(images, 'id')
      let shortImag = uniqueData?.sort(function (a, b) {
        return a?.order - b?.order
      })
      let allImg = shortImag
      shortImag?.map((item, index) => {
        allImg[index] = {
          ...allImg[index],
          original: item?.image,
          thumbnail: item?.image,
        }
      })
      setCarouselImg(allImg)
    }
  }, [images])

  useEffect(() => {
    getMachineDetails()
  }, [])

  const handlePhonePress = () => {
    if (!!data?.dealer?.contact_user?.phone) {
      return (
        <div className="font-bold text-[#666666] text-[13px] break-all">
          <a
            href={`callto:${data?.dealer?.contact_user?.phone}`}
            className="uppercase text-[13px] tracking-[1px] flex items-center"
          >
            <img src={phoneFlipIcon} alt="" className="w-[13px]" />
            <span className="ml-2 text-[#666666] font-bold">
              {data?.dealer?.contact_user?.phone}{' '}
            </span>
            <img src={externalFlipIcon} alt="" className="w-[13px] ml-1" />
          </a>
        </div>
      )
    }
  }

  const handleEmailPress = () => {
    if (!!data?.dealer?.contact_user?.email) {
      return (
        <div className="font-bold text-[#666666] text-[13px] break-all">
          <a
            href={`mailto:${data?.dealer?.contact_user?.email}`}
            className="text-[13px] tracking-[1px] flex items-center break-all"
          >
            <img src={emailFlipIcon} alt="" className="w-[13px]" />
            <span className="ml-2 text-[#666666] font-bold">
              {data?.dealer?.contact_user?.email}{' '}
            </span>
            <img src={externalFlipIcon} alt="" className="w-[13px] ml-1" />
          </a>
        </div>
      )
    }
  }

  const handleContactInfo = () => {
    if (
      !!!data?.dealer?.contact_user?.phone &&
      !!!data?.dealer?.contact_user?.email
    ) {
      return <div className="flex flex-col">no contact info available</div>
    }
  }
  const handlePriceValue = item => {
    const handleOnPressCall = () => {
      if (item?.dealer?.contact_user?.phone) {
        return `callto:${item?.dealer?.contact_user?.phone}`
      } else if (item?.dealer?.contact_user?.email) {
        return `mailto:${item?.dealer?.contact_user?.email}`
      } else {
        return 'javascript:void(0)'
      }
    }
    if (item?.advertised_price?.fractional != '0') {
      return `${item?.advertised_price?.fractional} ${item?.advertised_price?.currency?.symbol}`
    } else {
      return (
        <a
          href={handleOnPressCall()}
          className="text-[1.3rem] text-[#3a658e] font-bold ml-3"
        >
          Call for Price
        </a>
      )
    }
  }

  const navigate = useNavigate()
  return (
    <>
      {showModal && (
        <ContactUsModel
          setShowModal={setShowModal}
          name={`${data?.year} ${data?.manufacturer?.name} ${data?.model?.number}`}
        />
      )}
      <Layout>
        <div className="bg-white">
          <div className="custom-container py-[25px] lg:py-[70px] w-full lg:w-[70%]">
            {isLoading ? (
              <div
                className=" flex justify-center items-center"
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  marginTop: '30px',
                  height: '100vh',
                }}
              >
                <div className="spinner-container">
                  <div className="loading-spinner"></div>
                </div>
              </div>
            ) : (
              <>
                <div className="flex items-center justify-between flex-wrap">
                  <div>
                    <div className="text-[16px] text-[#666666] mt-10">
                      {data?.categorization_breadcrumb}
                    </div>
                    <div
                      className="text-[13px] text-[#7A7A7A] my-2 font-bold cursor-pointer"
                      onClick={() => {
                        navigate(`/inventory`)
                      }}
                    >{`<< Search Results`}</div>
                  </div>
                  <div className="flex">
                    <button
                      className="bg-[#F3F3F3] text-[#555555] font-bold text-[14px] px-[30px] py-[6px] border border-[silver] rounded flex justify-center items-center"
                      onClick={() => {
                        window.print()
                      }}
                    >
                      <img src={PrintIcon} className="w-[14px] mr-1" />
                      {InventoryDetail_Content?.PRINT}
                    </button>
                    <button
                      className="bg-[#F3F3F3] text-[#555555] font-bold text-[14px] px-[30px] py-[6px] border border-[silver] rounded flex justify-center items-center ml-2"
                      onClick={() => {
                        setShowModal(!showModal)
                      }}
                    >
                      <img src={shareIcon} className="w-[14px] mr-1" />{' '}
                      {InventoryDetail_Content?.SHERE}
                    </button>
                  </div>
                </div>
                <div className="block lg:hidden my-10 mx-5">
                  <div className="text-[25px] text-black font-bold text-[#666666]">
                    {data?.year} {data?.manufacturer?.name}{' '}
                    {data?.model?.number}
                  </div>
                  <div className="text-[13px] text-black pt-1">
                    {data?.categorization_breadcrumb}
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row">
                  <div className="w-full lg:w-[50%]">
                    <div className="w-full">
                      <ImageGallery
                        items={carouselImg}
                        infinite={true}
                        showPlayButton={false}
                        autoPlay={true}
                        useBrowserFullscreen={false}
                        originalWidth={CarouselStyle.width}
                        originalHeight={CarouselStyle.height}
                        slideInterval={5000}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-[50%] px-[20px]">
                    <div className="border-b-2 border-b-[#dddddd] py-1 hidden lg:block">
                      <div className="text-[25px] text-black font-bold">
                        {data?.year} {data?.manufacturer?.name}{' '}
                        {data?.model?.number}
                      </div>
                      <div className="text-[13px] text-black pt-1">
                        {data?.categorization_breadcrumb}
                      </div>
                    </div>
                    <div className="flex justify-start sm:justify-center lg:justify-start py-2">
                      <div>{data?.advertised_price?.currency?.iso_code}</div>
                      <div className="text-[1.5rem] text-[#3a658e] font-bold ml-3">
                        {/* {data?.advertised_price?.currency?.symbol}
                        {data?.advertised_price?.fractional} */}
                        {handlePriceValue(data)}
                      </div>
                    </div>
                    <div className="flex flex-col items-center py-3">
                      <div className="flex flex-col justify-center sm:flex-row lg:flex-col items-start w-full">
                        <button
                          className="bg-[#7a7a7a] text-white text-[13px] px-12 py-3 font-bold rounded-full w-full sm:w-auto flex items-center"
                          onClick={() => setShowModal(!showModal)}
                        >
                          <img
                            src={EmailIcone}
                            alt=""
                            className="w-[13px] mr-1"
                          />
                          {InventoryDetail_Content?.EMAIL_SELLER}
                        </button>
                      </div>
                      <div className="flex mt-5 flex-wrap w-full mb-5">
                        <div className="text-[13px] font-bold">
                          {InventoryDetail_Content?.MACHINE_LOCATION}
                        </div>
                        <div>{data?.dealer?.location ?? ''}</div>
                        {data?.dealer?.location && (
                          <img
                            src={externalFlipIcon}
                            alt=""
                            className="w-[13px] ml-2"
                          />
                        )}
                      </div>
                      <div className="bg-[#f3f3f3] px-[20px] py-[25px] w-full rounded-lg">
                        <div className="font-bold border-b border-[#dddddd] text-[#666666] text-[18px] pb-3">
                          {InventoryDetail_Content?.SELLER_INFORMATION}
                        </div>

                        <div className="mt-2">
                          <div>
                            <div className="font-bold text-[#666666] text-[13px] break-all">
                              {InventoryDetail_Content?.CONTACT}:
                              <span className="font-normal ml-2">
                                {data?.dealer?.contact_user?.first_name}{' '}
                                {data?.dealer?.contact_user?.last_name}
                              </span>
                            </div>
                            <div className="font-normal text-[#666666] text-[13px] break-all">
                              {data?.dealer?.address}
                            </div>
                          </div>

                          {/* ----------- Seller Info Phone and Email ---------- */}
                          <div>
                            {/* --------------- Seller Info Phone  ---------------- */}
                            {handlePhonePress()}

                            {/* --------------- Seller Info Email  ---------------- */}
                            {handleEmailPress()}
                            {handleContactInfo()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: machine_general_html,
                    }}
                    className="mt-5 htmlTable"
                  ></div>

                  <h1 className="text-[20px] font-bold mt-6">Description</h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.machine_description_html,
                    }}
                    className="mt-2 htmlTable descriptionBox"
                  ></div>
                </div>
              </>
            )}
            <div className="my-10 text-[16px]">{Inventory_Notice?.Notice}</div>
          </div>
        </div>
      </Layout>
    </>
  )
}
const CarouselStyle = {
  width: '1080px',
  height: '600px',
  thumbnailWidth: '100px',
  captionStyle: {
    display: 'none',
  },
  layout: {
    textAlign: 'center',
    maxWidth: '1080px',
    maxHeight: '100%',
    minHeight: '100%',
    margin: '50px auto 90px auto',
  },
}
export default InventoryDetails
