import LUKE_BRENNER from '../assets/Img/LUKE_BRENNER.jpg';
import ROLAND_ZOCH from '../assets/Img/ROLAND_ZOCH.png';
import KRISTEN_GONZALES from '../assets/Img/KRISTEN_GONZALES.jpg';
import ROGER_SMITH from '../assets/Img/ROGER_SMITH.jpg';

export const ApplicationId = "8CA3D0F6-016E-4A6F-BDB3-FF2D07C074CB"
export const ReCAPTCHA_sitekey ="6LdGI8MiAAAAACcTvJTaF6FkJtSQxP2grVBFOFYp"

export const  MachineList_Content = {
  EQUIPMENT_SALE:'Equipment For Sale',
  SORT_BY:'Sort By'
}
export const Contact_Details = {
  PHONE_NUM:"5124284626",
  PHONE_NUM_STRING:"(512) 428-4626",
  EMAIL:"sales@heavyequipmentco.com",
  OFFICE_LOCATION:"9605 FM 812, Austin, TX 78719"
};

export const Header_Content = {
  MENU:'Menu',
  AUTHORIZED_DEALER:'AUTHORIZED DEALER',
  OUR_COMPANY: "Our Company",
  INVENTORY: "Inventory",
  PARTS_STORE: "Parts Store",
  FINANCING: "Financing",
  CONTACT_US: "Contact Us",
  HEAVY_EQUIPMENT:"Heavy Equipment Co | "
};

export const Banner_Content ={
  WELCOME_HEAVY_EQUIPMENT:"Welcome to Heavy Equipment Co",
  PRE_OWNED_CONSTRUCTION:"PRE-OWNED CONSTRUCTION",
  EQUIPMENT:"EQUIPMENT",
  CHECK_INVENTORY:"Please check out our inventory.",
  CONSTRUCTION_EQUIPMENT:"Browsing for a new excavator, dozer,  or any other construction equipment?",
  LOOKING_SELL:"Are you looking to sell your equipment?",
  INTERESTED_IN_BUYING:"Please tell us about any equipment you’re interested in buying or selling",
}

export const Button_Text ={
  FOR_SALE:"FOR SALE",
  CONTACT_US:"CONTACT US",
  FIND_LOCATION:"FIND OUR LOCATION"
}

export const About_Text ={
  FIRST_CHOICE:"THE FIRST CHOICE",
  PERSONAL_RELATIONSHIPS:"We Pride ourselves in personal relationships with our customers, from those down the street to those around the world. Whether you are in Texas or across the country, know your construction needs are in good hands with our dedicated team.",
  HEAVY_EQUIPMENT_CONSTRUCTION_EQUIPMENT:"Heavy Equipment Co. is the first choice for buying or selling used construction equipment. Our experience and knowledge provides strong value to local contractors and large equipment fleets around the world. We can provide machines delivered to your door throughout the united states and internationally.",
  INDUSTRY_PROFESSIONALS:"If we don’t have the machine you are looking for in inventory, we can assist in locating, purchasing, and shipping the machine you need through our vast array of industry professionals. Downtime is costly, so don’t let logistics prevent you from getting back up and running.",
  CUSTOMERS_IN_COMMERCIAL:"We successfully assist a variety of customers in commercial, residential, industrial, agricultural, and municipal industries. Our equipment fleet includes a wide range of excavation equipment to help with your project."
}

export const Review_Content ={
  WHAT_CUSTOMERS_SAY:'WHAT CUSTOMERS SAY'
}

export const Review_Carousel_List = [
  {
    name:'Tre L.',
    text:`" Heavy Equipment Co has been a great supplier for us. We’ve rented, purchased, and sold equipment with them directly off our jobsites. The transactions have always gone smoothly, and most importantly the equipment has been accurately represented. "`,
  },
  {
    name:'Kevin G.',
    text:`" Heavy Equipment Co. is our supplier of choice for rentals and used equipment purchases, locally in Texas, and for projects as far as Colorado. They get us the equipment they say they’re going to, when they say they’re going to. "`
  },
  {
    name:"Chris J.",
    text:`" Luke and his team have provided us used equipment for both domestic resale, and export. Heavy Equipment Co. has been a valuable resource and excellent partner. "`,
  },
]

export const Footer_Content ={
  EQUIPMENT_INDUSTRY:"We strive to deliver the best value in the used equipment industry by providing a fair deal and putting our customers first. We provide equipment sales, rentals, and parts to keep your projects moving forward."
}

export const Our_Company_Content = {
  OUR_COMPANY:"OUR COMPANY",
  HEAVY_EQUIPMENT:"Heavy Equipment Co.",
  BASED_IN_AUSTIN:"Heavy Equipment Co. is based in Austin, TX. We pride ourselves in personal relationships with our customers, from those down the street to international customers across the globe. We strive to deliver the best value in the used equipment industry by providing a fair deal and putting our customers first. We provide equipment sales, rentals, and parts to keep your projects moving forward."
}

export const Contact_Content ={
  CONTACT_US:'CONTACT US',
  CONTACT_FORM:'CONTACT FORM',
  CONTACT_INFO:'Contact Info',
  SECURITY_PURPOSES:'For security purposes, please enter the characters from the box above:'
}

export const Company_Owner_List = [
  {
    img:LUKE_BRENNER,
    Name:'LUKE BRENNER',
    position:'Owner',
    bio:'After years of managing the sales department for a large independent equipment company, Luke started Heavy Equipment Co. in January of 2013. Luke grew up in Southwest Michigan and is a graduate of Culver Military in Culver, IN and Hope College in Holland, MI. He and his wife, Elizabeth, have two young daughters who keep them very busy. In addition to the equipment business, they also raise Wagyu Cattle. In fleeing moments for free time, Luke enjoys hunting, fishing, and camping with his family.',
  },
  {
    img:ROLAND_ZOCH,
    Name:'ROLAND ZOCH',
    position:'Equipment Sales & Rentals',
    bio:'With nearly 17 years in the industry, Roland has established a career in the buying and selling of heavy equipment based on customer service, attention to detail, and a positive attitude. He, his wife, Robbie, and their children, Annabelle and Jordi, reside in Giddings, TX, about 5 miles from where Roland grew up. He takes great pride in being from and living in a small town, and although his job has taken him many miles away from there over the years, he knows where his “home “ is. Roland is a Certified Crossfit Trainer and an “athlete”. He enjoys spending time in his gym, on the golf course, and at the ballfield. His other hobbies include reading, writing, fundraising, BBQ, and finding ways to “improve the quality of life” for he and his family.',
  },
  {
    img:KRISTEN_GONZALES,
    Name:'KRISTEN GONZALES',
    position:'Office Manager',
    bio:'Born and raised in Austin, TX, Kristen is a proud mother of 3 wonderful kids. She has over 10 years of experience in Customer Service and Office Management.  She’s taken great pride her in work since joining the team in September 2019.  On the weekends she enjoys making memories with her family and living to the fullest.  Kristen has resided in Bastrop, TX since 2001 and loves the small town life.  At Heavy Equipment Co. she wears many hats and does what’s needed to get the job done!',
  },
  {
    img:ROGER_SMITH,
    Name:'ROGER SMITH',
    position:'Equipment Sales & Rentals',
    bio:'Roger Smith has spent the vast majority of his life in and around the equipment business. Prior to joining Heavy Equipment Co. Roger spent 21 years with a national after-market parts supplier. Roger and his wife reside in East Texas, while spending numerous days a month in the Dallas/Fort Worth area assisting customers. True to his Texas roots, Roger greatly enjoys hunting and fishing, and spends as much time as he can with his grandchildren.',
  },
]

export const Inventory_List =[
  {
    Img:'',
    Name:'2011 BROCE RJT350',
    Type:'Sweepers / Broom Equipment',
    Price:'USD $17,500',
    MachineLocation:'Austin, Texas 78719',
    Hours:'1,103',
    Drive:'-',
    ROPS:'Enclosed',
    Condition:'Used',
    BackAuxiliaryHydraulics:'-',
    AC:'Yes',
  },
  {
    Img:'',
    Name:'2014 CASE 580N',
    Type:'Loader Backhoes',
    Price:'USD $48,500',
    MachineLocation:'Austin, Texas 78719',
    Hours:'2,029',
    Drive:'4 WD',
    ROPS:'Open',
    Condition:'Used',
    BackAuxiliaryHydraulics:'Yes',
    AC:'-',
  },
  {
    Img:'',
    Name:'2016 CATERPILLAR 259D',
    Type:'Track Skid Steers',
    Price:'USD $36,500',
    MachineLocation:'Austin, Texas 78719',
    Hours:'4,170',
    Drive:'-',
    ROPS:'Open',
    Condition:'Used',
    BackAuxiliaryHydraulics:'-',
    AC:'-',
  },
  {
    Img:'',
    Name:'2011 BROCE RJT350',
    Type:'Sweepers / Broom Equipment',
    Price:'USD $17,500',
    MachineLocation:'Austin, Texas 78719',
    Hours:'1,103',
    Drive:'-',
    ROPS:'Enclosed',
    Condition:'Used',
    BackAuxiliaryHydraulics:'-',
    AC:'Yes',
  },
  {
    Img:'',
    Name:'2014 CASE 580N',
    Type:'Loader Backhoes',
    Price:'USD $48,500',
    MachineLocation:'Austin, Texas 78719',
    Hours:'2,029',
    Drive:'4 WD',
    ROPS:'Open',
    Condition:'Used',
    BackAuxiliaryHydraulics:'Yes',
    AC:'-',
  },
  {
    Img:'',
    Name:'2016 CATERPILLAR 259D',
    Type:'Track Skid Steers',
    Price:'USD $36,500',
    MachineLocation:'Austin, Texas 78719',
    Hours:'4,170',
    Drive:'-',
    ROPS:'Open',
    Condition:'Used',
    BackAuxiliaryHydraulics:'-',
    AC:'-',
  },
  {
    Img:'',
    Name:'2011 BROCE RJT350',
    Type:'Sweepers / Broom Equipment',
    Price:'USD $17,500',
    MachineLocation:'Austin, Texas 78719',
    Hours:'1,103',
    Drive:'-',
    ROPS:'Enclosed',
    Condition:'Used',
    BackAuxiliaryHydraulics:'-',
    AC:'Yes',
  },
  {
    Img:'',
    Name:'2014 CASE 580N',
    Type:'Loader Backhoes',
    Price:'USD $48,500',
    MachineLocation:'Austin, Texas 78719',
    Hours:'2,029',
    Drive:'4 WD',
    ROPS:'Open',
    Condition:'Used',
    BackAuxiliaryHydraulics:'Yes',
    AC:'-',
  },
  {
    Img:'',
    Name:'2016 CATERPILLAR 259D',
    Type:'Track Skid Steers',
    Price:'USD $36,500',
    MachineLocation:'Austin, Texas 78719',
    Hours:'4,170',
    Drive:'-',
    ROPS:'Open',
    Condition:'Used',
    BackAuxiliaryHydraulics:'-',
    AC:'-',
  },
  {
    Img:'',
    Name:'2011 BROCE RJT350',
    Type:'Sweepers / Broom Equipment',
    Price:'USD $17,500',
    MachineLocation:'Austin, Texas 78719',
    Hours:'1,103',
    Drive:'-',
    ROPS:'Enclosed',
    Condition:'Used',
    BackAuxiliaryHydraulics:'-',
    AC:'Yes',
  },
  {
    Img:'',
    Name:'2014 CASE 580N',
    Type:'Loader Backhoes',
    Price:'USD $48,500',
    MachineLocation:'Austin, Texas 78719',
    Hours:'2,029',
    Drive:'4 WD',
    ROPS:'Open',
    Condition:'Used',
    BackAuxiliaryHydraulics:'Yes',
    AC:'-',
  },
  {
    Img:'',
    Name:'2016 CATERPILLAR 259D',
    Type:'Track Skid Steers',
    Price:'USD $36,500',
    MachineLocation:'Austin, Texas 78719',
    Hours:'4,170',
    Drive:'-',
    ROPS:'Open',
    Condition:'Used',
    BackAuxiliaryHydraulics:'-',
    AC:'-',
  },
]

export const Inventory_Notice = {
  Notice:"*Notice: Financing terms available may vary depending on applicant and/or guarantor credit profile(s) and additional approval conditions. Assets aged 10-15 years or more may require increased finance charges. Financing approval may require pledge of collateral as security. Applicant credit profile including FICO is used for credit review. Commercial financing provided or arranged by Express Tech-Financing, LLC pursuant to California Finance Lender License #60DBO54873. Consumer financing arranged by Express Tech-Financing, LLC pursuant to California Finance Lender License #60DBO54873 and state licenses listed at this "
}

export const Privacy_Policy_Content ={
  CLICK_POLICY:"Click here to acknowledge you understand our ",
  PRIVACY_POLICY:'Privacy Policy',
  POLICY_DETAILS:', which explains how the Sandhills Group Companies use and collect personal information when you register with us or place an order for any of our Brands. We will not share your information for marketing purposes with companies outside our group companies and you may withdraw your consent at any time.',
  EMAIL_FRIEND:'Email this to a friend'
}

export const InventoryDetail_Content ={
  PRINT:"Print",
  SHERE:"Share",
  SWEEPERS:" Sweepers / Broom Equipment",
  EMAIL_SELLER:"Email Seller",
  MACHINE_LOCATION:"Machine Location:",
  SELLER_INFORMATION:"Seller Information",
  HEAVY_EQUIPMENT:"Heavy Equipment Co.",
  CONTACT:'Contact',
}