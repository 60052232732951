import api from '../api/api'
import postApi from '../api/postApi'
import * as url from '../api/url'

// Post Contact us Api
export const postContactUs = (payload) => {
    return new Promise(async (resolve, reject) => {
      return postApi
        .post(url.POST_CONTACT_US , payload)
        .then(response => {
          if (response) {
            resolve(response)
          }
        })
        .catch(err => {
          reject(err.response)
        })
    })
  }
  