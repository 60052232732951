import Header from "./header/header";
import Footer from "./footer/footer";
import { useState, useEffect } from "react";
import UpArrow from "../../assets/svg/up-arrow.svg"
const Layout = (props) => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      {showTopBtn && (
        <button
          type="button"
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="fixed inline-block p-3 bg-black text-white font-medium text-xs leading-tight uppercase shadow-md hover:bg-black hover:shadow-lg focus:bg-black focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out bottom-5 right-5 text-[16px] capitalize "
          id="btn-back-to-top"
          onClick={goToTop}
        >
          <img src={UpArrow} className="w-[16px]"/>
          <div className="mt-1">
            Top
            </div>
        </button>
      )}
      {/* --------------- Header --------------- */}
      <Header authorized_banner={props?.authorized_banner} />

      {props?.children}

      {/* --------------- Footer --------------- */}
      <Footer />
    </>
  );
};
export default Layout;
