import { Company_Owner_List } from "../../../utils/text_content";
const CompanyOwner = () => {
  return (
    <>
      <div className="bg-[#212121]">
        <div className="custom-container py-[25px] lg:py-[70px]">
          <div className="grid grid-cols-1 lg:grid-cols-2 grid-flow-row gap-8">
            {Company_Owner_List?.map((item, index) => {
              return (
                <div className="bg-[#2F2F2F]">
                  <img src={item?.img} alt="" className="h-[200px] sm:h-[300px] lg:h-[400px] w-full  object-cover"/>
                  <div className="w-[95%] lg:w-[80%] m-auto py-10">
                    <h2 className="text-[25px] font-bold text-white">{item?.Name}</h2>
                    <h4 className="text-[17px] font-medium text-[#edac15]">{item?.position}</h4>
                    <div className="text-[15px] font-bold text-[#fff] mt-5">Bio</div>
                    <div className="text-[15px] text-[#fff] tracking-[1px]">{item?.bio}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default CompanyOwner;
