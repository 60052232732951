import CatSmall from "../../../assets/Img/cat_small.png";
import Volvo from "../../../assets/Img/volvo.png";
import JohnDeere from "../../../assets/Img/john_deere.png";
import Komatsu from "../../../assets/Img/komatsu.png";
import CircleRightRegular from "../../../assets/svg/circle-right-regular.svg";
import { About_Text, Button_Text } from "../../../utils/text_content";
import { useNavigate } from "react-router-dom";
const About = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-white">
        <div className="custom-container py-[25px] lg:py-[70px] flex flex-col lg:flex-row">
          <div className="w-full lg:w-[50%]">
            <div className="text-center lg:text-start">
              <h1 className="text-[32px]">{About_Text?.FIRST_CHOICE}</h1>
            </div>
            <h4 className="py-[25px] text-[#585858] text-[20px] font-normal leading-[30px] border-b-2 border-b-black">
              {About_Text?.PERSONAL_RELATIONSHIPS}
            </h4>
            <button className="bg-[#E9E9E9] hover:bg-[#4f4f4f] text-[#edac15] my-3 py-2 px-4 rounded text-[15px] tracking-[1px] flex items-center"  onClick={()=>navigate('/contact')}>
              {Button_Text?.FIND_LOCATION}
              <img src={CircleRightRegular} className="w-[13px] mr-2 ml-2" alt="" />
            </button>
          </div>
          <div className="w-full lg:w-[50%] px-0 lg:px-[20px]">
            <h4 className="py-[25px] text-[#585858] text-[20px] font-normal leading-[30px]">
              {About_Text?.HEAVY_EQUIPMENT_CONSTRUCTION_EQUIPMENT}
            </h4>
            <h4 className="py-[25px] text-[#585858] text-[20px] font-normal leading-[30px]">
              {About_Text?.INDUSTRY_PROFESSIONALS}
            </h4>
            <h4 className="py-[25px] text-[#585858] text-[20px] font-normal leading-[30px]">
              {About_Text?.CUSTOMERS_IN_COMMERCIAL}
            </h4>
          </div>
        </div>
        <div className="bg-[#E9E9E9] border-t-4 border-t-black">
          <div className="custom-container flex py-[25px]">
            <div className="w-full lg:w-[80%] m-auto flex items-center justify-between flex-wrap">
              <div>
                <img src={CatSmall} alt="Cat_Small" className="max-w-[130px]" />
              </div>
              <div>
                <img src={Volvo} alt="Cat_Small" className="max-w-[280px]" />
              </div>
              <div>
                <img
                  src={JohnDeere}
                  alt="Cat_Small"
                  className="max-w-[280px]"
                />
              </div>
              <div>
                <img src={Komatsu} alt="Cat_Small" className="max-w-[280px]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default About;
