import RecaptchaImage from "../../../assets/Img/recaptchaImage.jpg";
import { Contact_Content } from "../../../utils/text_content";
import FormInput from "../../../components/formInput/formInput";
import { Formik, Form } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { useRef, useState } from "react";
import { postContactUs } from "../../../services/contactUsService";
import { ApplicationId } from "../../../utils/text_content";
import { toast } from "react-toastify";
import { ReCAPTCHA_sitekey } from "../../../utils/text_content";
const ContactForm = () => {
  const [showCode, setShowCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const reCaptchaRef = useRef(null);
  const handleSubmitForm = (value, resetForm) => {
    const htmlForm = `
    <div style="font-size:25px">
      <div>
        <table>
          <tr style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${
              value?.firstname ?? ""
            }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${
              value?.lastname ?? ""
            }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${
              value?.email ?? ""
            }" style="color:#551A8B;text-decoration: underline;">${
      value?.email ?? ""
    }</a></td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${
              value?.phone ?? ""
            }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Zip</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${
            value?.zip ?? ""
          }</td>
        </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">City</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${
              value?.city ?? ""
            }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Message</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;padding-right:40px;max-width: 2600px;line-break: anywhere;">${
              value?.message ?? ""
            }</td>
          </tr>
        </table>
      <div>
    </div>
  `;
    setIsLoading(true);
    let payload = {
      HtmlContent: htmlForm,
      ApplicationId: ApplicationId,
    };
    postContactUs(payload)
      .then((res) => {
        toast.success(res?.data);
        reCaptchaRef?.current?.reset();
        setIsLoading(false);

        resetForm();
      })
      .catch((err) => {
        toast.error(err?.data);
        setIsLoading(false);
      });
  };

  const AccSchema = Yup.object().shape({
    firstname: Yup.string().required("Please Enter First Name"),
    lastname: Yup.string().required("Please Enter Last Name"),
    email: Yup.string()
      .email("Enter Valid Email Address")
      .required("Please Enter E-mail Address"),

    phone: Yup.string().required("Please Enter Phone Number"),
    city: Yup.string().required("Please Enter City Name"),
    zip: Yup.string().required("Please Enter Zip Code"),
    reCaptcha: Yup.string().required("recaptcha is a required field"),
  });
  return (
    <>
      <div className="bg-[#212121]">
        <div className="custom-container py-[25px] lg:py-[70px]">
          <div className="text-[#edac15] text-center py-[25px] text-[32px] font-bold border-b-2 border-b-white">
            {Contact_Content?.CONTACT_FORM}
          </div>
          <div>
            <Formik
              initialValues={{
                firstname: "",
                lastname: "",
                email: "",
                phone: "",
                zip: "",
                city: "",
                message: "",
                reCaptcha: "",
              }}
              validationSchema={AccSchema}
              onSubmit={(values, { resetForm }) => {
                handleSubmitForm(values, resetForm);
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                setFieldValue,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="p-5 grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-8">
                    <FormInput
                      title="First Name"
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      touched={touched}
                      id="userfirstname"
                      name="firstname"
                      type="text"
                    />
                    <FormInput
                      title="Last Name"
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      touched={touched}
                      id="lastname"
                      name="lastname"
                      type="text"
                    />
                    <FormInput
                      title="Phone"
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      touched={touched}
                      id="phone"
                      name="phone"
                      type="number"
                    />
                    <FormInput
                      title="Email"
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      touched={touched}
                      id="email"
                      name="email"
                      type="email"
                    />
                    <FormInput
                      title="zip Code"
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      touched={touched}
                      id="zip"
                      name="zip"
                      type="number"
                      placeholder="Enter your first name"
                    />
                    <FormInput
                      title="City"
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      touched={touched}
                      id="city"
                      name="city"
                      type="text"
                    />
                  </div>
                  <div className=" px-5 mb-3 flex flex-col">
                    <label className="text-white text-[16px] py-2 text-center">
                      Message :
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      rows="4"
                      className="form-control p-2"
                      value={values.message}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="text-center mt-5 mb-5">
                    <button
                      className="uppercase text-[#edac15] italic font-bold"
                      // disabled={isLoading}
                      // className={`mb-2 ml-8 ${
                      //   isLoading
                      //     ? "bg-[#1010104D] cursor-not-allowed	"
                      //     : "bg-blue-500"
                      // } ${
                      //   isLoading ? "bg-[#1010104D]" : "hover:bg-yellow-600"
                      // } text-white font-bold py-2 px-4 rounded mt-8`}
                      onClick={() => {
                        setShowCode(true);
                      }}
                    >
                      Continue
                    </button>
                  </div>
                  {showCode && (
                    <div className="w-[70%] m-auto flex flex-col items-center">
                      <ReCAPTCHA
                        ref={reCaptchaRef}
                        size="normal"
                        sitekey={ReCAPTCHA_sitekey}
                        onChange={(captchaCode) => {
                          setFieldValue("reCaptcha", captchaCode);
                        }}
                      />
                      {errors?.reCaptcha && touched?.reCaptcha && (
                        <div
                          style={{ fontSize: 14, textAlign: "left" }}
                          className=" mt-1 text-red-600"
                        >
                          {errors?.reCaptcha}
                        </div>
                      )}
                      <button
                        type="submit"                        
                        className={`uppercase bg-[#edac15] px-5 py-2 text-white mt-4 ${isLoading?"cursor-not-allowed":"cursor-pointer"}`}
                        disabled={isLoading}
                      >
                        SUBMIT
                      </button>
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactForm;
