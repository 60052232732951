import { Contact_Content, Contact_Details } from "../../../utils/text_content";
const Map = () => {
  return (
    <>
      <div className="bg-[#ddd]">
        <div className="flex flex-col lg:flex-row">
          <div className="w-[100%] lg:w-[50%]">
            <div style={{ height: "350px", width: "100%" }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3449.757304881213!2d-97.680975!3d30.158354!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865b467bbdbb024f%3A0x93ca975607d4f3a6!2sHeavy%20Equipment%20Co.!5e0!3m2!1sen!2sus!4v1667845223701!5m2!1sen!2sus"
                width="100%"
                height="100%"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="w-[100%] lg:w-[50%] py-[30px] px-10 lg:px-5 lg:pl-[100px]">
            <div className="text-[#EDAC15] text-[26px] py-[25px] border-b-2 border-b-white w-[80%]">
              {Contact_Content?.CONTACT_INFO}
            </div>
            <div className="pt-[25px]">
              <div className="flex">
                <div className="text-[16px] font-bold">Office Location:</div>
                <div className="ml-2">{Contact_Details?.OFFICE_LOCATION}</div>
              </div>
              <div className="flex">
                <div className="text-[16px] font-bold">Phone:</div>
                <a
                  href={`callto:${Contact_Details?.PHONE_NUM}`}
                  className="ml-2 text-black hover:text-[#EDAC15]"
                >
                  {Contact_Details?.PHONE_NUM_STRING}
                </a>
              </div>
              <div className="flex">
                <div className="text-[16px] font-bold">Email:</div>
                <a
                  href={`mailto:${Contact_Details?.EMAIL}`}
                  className="ml-2 text-black hover:text-[#EDAC15]"
                >
                  {Contact_Details?.EMAIL}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Map;
