import { Our_Company_Content } from "../../../utils/text_content";
import Heavy_Equipment from "../../../assets/Img/Heavy_Equipment.jpg"
const HeavyEquipmentInfo = () => {
  return (
    <>
      <div className="bg-white">
        <div className="custom-container py-[25px] lg:py-[70px]">
          <div className="w-full lg:w-[90%] xl:w-[80%] m-auto">
            <div className="flex flex-col lg:flex-row">
              <div className="w-full lg:w-[50%] p-5">
                <div>
                  <h1 className="text-[#edac15] text-[32px] leading-[35px]">{Our_Company_Content?.HEAVY_EQUIPMENT}</h1>
                  <div className="text-[16px] tracking-[0.4px] mt-[30px]">
                    {Our_Company_Content?.BASED_IN_AUSTIN}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-[50%] p-5">
                <img src={Heavy_Equipment} className='w-full'/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HeavyEquipmentInfo;
