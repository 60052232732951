import EmailIcon from '../../../assets/svg/square-email.svg'
import PhoneIcon from '../../../assets/svg/square-phone.svg'
import SunwardLogo from '../../../assets/Img/sunward-logo.png'
import logo from '../../../assets/Img/logo.jpg'
import Drawer from './Drawer'
import {useState} from 'react'
import {Contact_Details, Header_Content} from '../../../utils/text_content'

const Header = ({authorized_banner}) => {
  const [isOpen, setIsOpen] = useState(false)
  const pageLinkList = [
    {
      title: Header_Content?.OUR_COMPANY,
      link: '/our-company',
      target: '',
    },
    {
      title: Header_Content?.INVENTORY,
      link: '/inventory',
      target: '',
    },
    {
      title: Header_Content?.FINANCING,
      link: 'https://heavyequipmentco.directcapital.com/',
      target: '_blank',
    },
    {
      title: Header_Content?.CONTACT_US,
      link: '/contact',
      target: '',
    },
  ]

  return (
    <>
      <div className="bg-white border-b border-b-[#d2d2d2]">
        <div className="custom-container">
          <nav
            class={`flex items-center justify-between flex-wrap bg-[#fff] flex-col lg:flex lg:flex-row w-full lg:w-[90%]`}
          >
            <a href="/">
              <div class="flex items-center flex-shrink-0 text-white mr-6 py-6">
                <img
                  src={logo}
                  className="w-[250px] lg:w-[300px] xl:w-[350px]"
                  alt=""
                />
              </div>
            </a>

            {/* ----------- md Screen Header -------------- */}
            <div class="block lg:hidden flex items-center flex-col">
              <div className="flex">
                <div className="flex flex-wrap	">
                  <div className="flex items-center ml-[7px]">
                    <img
                      src={PhoneIcon}
                      className="w-[13px] mr-2"
                      alt="phone"
                    />
                    <a
                      href={`callto:${Contact_Details?.PHONE_NUM}`}
                      className="uppercase text-[13px] tracking-[1px]"
                    >
                      {Contact_Details?.PHONE_NUM_STRING}
                    </a>
                  </div>
                  <div className="flex items-center ml-[7px]">
                    <img
                      src={EmailIcon}
                      className="w-[13px] mr-2"
                      alt="email"
                    />
                    <a
                      href={`mailto:${Contact_Details?.EMAIL}`}
                      className="uppercase text-[13px] tracking-[1px]"
                    >
                      {Contact_Details?.EMAIL}
                    </a>
                  </div>
                </div>
              </div>
              <button className="my-[10px]" onClick={() => setIsOpen(!isOpen)}>
                {Header_Content?.MENU} ☰
              </button>
            </div>

            {/* ------------ lg Screen Header -------------- */}
            <div class="hidden lg:block w-full flex-grow lg:flex lg:items-center lg:w-auto justify-end relative">
              <div>
                <div className="flex justify-end">
                  <div className="flex items-center ml-[7px]">
                    <img
                      src={PhoneIcon}
                      className="w-[15px] mr-2"
                      alt="phone"
                    />
                    <a
                      href={`callto:${Contact_Details?.PHONE_NUM}`}
                      className="uppercase text-[15px] tracking-[1px]"
                    >
                      {Contact_Details?.PHONE_NUM_STRING}
                    </a>
                  </div>
                  <div className="flex items-center ml-[7px]">
                    <img
                      src={EmailIcon}
                      className="w-[14px] mr-2"
                      alt="email"
                    />
                    <a
                      href={`mailto:${Contact_Details?.EMAIL}`}
                      className="uppercase text-[14px] tracking-[1px]"
                    >
                      {Contact_Details?.EMAIL}
                    </a>
                  </div>
                </div>
                <div class="flex justify-end">
                  {pageLinkList?.map((item, index) => {
                    return (
                      <a
                        href={item?.link}
                        target={item?.target}
                        class={`block mt-4 text-[#000] hover:text-[#8484a4] uppercase px-[7px] text-[12px]`}
                      >
                        {item?.title}
                      </a>
                    )
                  })}
                </div>
              </div>
              {authorized_banner && (
                <div className="absolute bg-white top-[103px] rounded-b-lg text-center px-[20px] py-[20px] shadow-lg shadow-indigo-[#333]">
                  <h4 className="font-bold border-b-2 border-b-[#ddd] pb-[10px]">
                    {Header_Content?.AUTHORIZED_DEALER}
                  </h4>
                  <img src={SunwardLogo} className="w-[170px] my-[10px]" />
                </div>
              )}
            </div>
          </nav>
        </div>
      </div>
      {/* ------------ Bottom Header Card md Screen --------------- */}
      {authorized_banner && (
        <div className="block lg:hidden bg-white top-[103px] text-center px-[20px] py-[20px] border-t-2 border-t-[#ddd] pb-[10px]">
          <div className="border-b-2 border-b-[#ddd] pb-[10px]">
            <h4 className="font-bold">{Header_Content?.AUTHORIZED_DEALER}</h4>
          </div>
          <img src={SunwardLogo} className="w-[250px] my-[10px] m-auto" />
        </div>
      )}

      {/* ------------ Md Screen sidebar --------------- */}
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <div class="flex flex-col">
          {pageLinkList?.map((item, index) => {
            return (
              <a
                href={item?.link}
                target={item?.target}
                class={`block text-[#171414] capitalize px-[7px] text-[15px] font-light border-t-2 border-[#454545] p-[10px] hover:text-gray hover:border-l-4 hover:border-l-[#EDAC15] border-l-[#EDAC15]`}
              >
                {item?.title}
              </a>
            )
          })}

          <div
            class={`block text-[#000] capitalize px-[7px] text-[14px] font-medium border-t-2 border-[#000] p-[10px] text-center `}
          >
            {Header_Content?.HEAVY_EQUIPMENT}
            {new Date().getFullYear()}
          </div>
        </div>
      </Drawer>
    </>
  )
}
export default Header
