import Layout from "../../components/layout/layout";
import MachineList from "./machineList/machineList";
const Inventory = () => {
  return (
    <>
      <Layout>
        <MachineList/>
      </Layout>
    </>
  );
};
export default Inventory;
