import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef, useState } from "react";
import { ApplicationId, Privacy_Policy_Content } from "../../../utils/text_content";
import { CountryCode } from "../../../data/countryCode";
import { countries } from "country-list-json";
import CountryList from "country-list-with-dial-code-and-flag";
import { getCountryListMap } from "country-flags-dial-code";
import startsWith from "lodash.startswith";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { postContactUs } from "../../../services/contactUsService";
import { ReCAPTCHA_sitekey } from "../../../utils/text_content";
const InputField = ({
  title,
  values,
  errors,
  handleChange,
  touched,
  id,
  name,
  type,
  placeholder,
}) => {
  return (
    <div className="mb-1 flex flex-col">
      <label className="text-black text-[16px] py-2 font-bold">{title}</label>
      <input
        type={type}
        id={id}
        name={name}
        className="form-control p-2 border-2 border-[#BEC1CF] rounded"
        value={values?.[`${name}`]}
        placeholder={placeholder}
        onChange={(e) => handleChange(e)}
      />

      {errors?.[`${name}`] && touched?.[`${name}`] && (
        <div
          style={{ fontSize: 14, textAlign: "left" }}
          className=" mt-1 text-red-600"
        >
          {errors?.[`${name}`]}
        </div>
      )}
    </div>
  );
};

const InputFieldError = ({ errors, name, touched }) => {
  return (
    <>
      {errors?.[`${name}`] && touched?.[`${name}`] && (
        <div
          style={{ fontSize: 14, textAlign: "left" }}
          className=" mt-1 text-red-600"
        >
          {errors?.[`${name}`]}
        </div>
      )}
    </>
  );
};

const ContactUsModel = ({ setShowModal, name }) => {
  console.log("name",name)
  const [isLoading, setIsLoading] = useState(false);
  const allCountryList = Object.values(getCountryListMap());
  const reCaptchaRef = useRef(null);
  const handleSubmitForm = (value, resetForm) => {
    const htmlForm = `
      <div style="font-size:25px">
        <div>
          <table>
            <tr style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                value?.firstname ?? ""
              }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                value?.lastname ?? ""
              }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${
                value?.email ?? ""
              }" style="color:#551A8B;text-decoration: underline;">${
      value?.email ?? ""
    }</a></td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                value?.phone ?? ""
              }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Postal Code</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                value?.postalCode ?? ""
              }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Message</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;padding-right:40px;max-width: 2600px;line-break: anywhere;">${
                value?.message ?? ""
              }</td>
            </tr>
          </table>
        <div>
      </div>
    `;

    const htmlFormWidthInventory = `
      <div style="font-size:25px">
        <div>
          <table>
            <tr style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                value?.firstname ?? ""
              }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                value?.lastname ?? ""
              }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${
                value?.email ?? ""
              }" style="color:#551A8B;text-decoration: underline;">${
                value?.email ?? ""
              }</a></td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                value?.phone ?? ""
              }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 40px 10px 0px 0px;color:#000;">Inventory</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                name ?? ""
              }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Postal Code</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                value?.postalCode ?? ""
              }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 40px 10px 40px;color:#000;">Message</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;max-width: 2600px;line-break: anywhere;">${
                value?.message ?? ""
              }</td>
            </tr>
          </table>
        <div>
      </div>
      `;
    setIsLoading(true);
    let payload = {
      HtmlContent: name ? htmlFormWidthInventory : htmlForm,
      ApplicationId: ApplicationId,
    };
    postContactUs(payload)
      .then((res) => {
        toast.success(res?.data);
        reCaptchaRef?.current?.reset();
        setIsLoading(false);

        resetForm();
      })
      .catch((err) => {
        toast.error(err?.data);
        setIsLoading(false);
      });
  };
  const AccSchema = Yup.object().shape({
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Enter Valid Email Address")
      .required("The email address entered is an invalid format."),

    phone: Yup.string().required("Please Enter Phone Number"),
    message: Yup.string().required("Message is required"),
    // termsAndConditions: Yup.bool().oneOf([true], "Required"),
    reCaptcha: Yup.string().required("recaptcha is a required field"),
  });
  return (
    <>
      <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-[#00000066]">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[90vh] overflow-auto	">
            <div className="flex items-start justify-between p-5 rounded-t ">
              <h3 className="text-[24px] font-semibold ">
                {name?name: Privacy_Policy_Content?.EMAIL_FRIEND}
              </h3>
              <button
                className="bg-transparent border-0 text-black float-right"
                onClick={() => setShowModal(false)}
              >
                <span className="text-black opacity-7 text-[30px] text-xl block text-[#aaa] py-0 rounded-full">
                  x
                </span>
              </button>
            </div>
            <div>
              <Formik
                initialValues={{
                  firstname: "",
                  lastname: "",
                  email: "",
                  phone: "",
                  postalCode: "",
                  city: "",
                  message: "",
                  termsAndConditions: false,
                  reCaptcha: "",
                }}
                validationSchema={AccSchema}
                onSubmit={(values, { resetForm }) => {
                  handleSubmitForm(values, resetForm);
                }}
              >
                {({
                  errors,
                  touched,
                  values,
                  handleChange,
                  setFieldValue,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="p-5 grid grid-cols-1 md:grid-cols-2 gap-8">
                      <div>
                        <InputField
                          title="First Name"
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          touched={touched}
                          id="userfirstname"
                          name="firstname"
                          type="text"
                          placeholder="Enter your first name"
                        />
                        <InputField
                          title="Last Name"
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          touched={touched}
                          id="lastname"
                          name="lastname"
                          type="text"
                          placeholder="Enter your last name"
                        />
                        <InputField
                          title="Email"
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          touched={touched}
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Enter your email address"
                        />
                        <label className="text-black text-[16px] py-2 font-bold">
                          Phone
                        </label>
                        <div class="flex flex-col">
                          <PhoneInput
                            country={"us"}
                            inputProps={{
                              id: "phone",
                              name: "phone",
                            }}
                            inputClass="w-[100%] border-solid border-2 border-[#BEC1CF] rounded-l-md"
                            inputStyle={{
                              width: "100%",
                              border: "2px solid #BEC1CF",
                              borderRadius: "0.25rem",
                            }}
                            value={values?.phone}
                            // onChange={(e) => handleChange(e)}
                            onChange={(e)=> setFieldValue('phone',e)}
                          />

                          {errors?.phone && touched?.phone && (
                            <div
                              style={{ fontSize: 14, textAlign: "left" }}
                              className=" mt-1 text-red-600"
                            >
                              {errors?.phone}
                            </div>
                          )}
                        </div>
                        <InputField
                          title="Postal Code"
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          touched={touched}
                          id="postalCode"
                          name="postalCode"
                          type="text"
                          placeholder="Enter your postal code"
                        />
                      </div>
                      <div className=" mb-3 flex flex-col">
                        <label className="text-black text-[16px] py-2 font-bold">
                          Message :
                        </label>
                        <textarea
                          id="message"
                          name="message"
                          rows="15"
                          className="form-control p-2 border-2 border-[#BEC1CF] rounded"
                          value={values.message}
                          placeholder="Enter your inquiry (Up to 500 characters)"
                          onChange={(e) => handleChange(e)}
                        />
                        <InputFieldError
                          errors={errors}
                          touched={touched}
                          name="message"
                        />
                      </div>
                    </div>
                    <div className="p-5 grid grid-cols-1 md:grid-cols-2 gap-8">
                      {/* <div>
                        <div className="text-[12px]">
                          <Field
                            type="checkbox"
                            name="termsAndConditions"
                            className="mr-1"
                          />
                          {Privacy_Policy_Content?.CLICK_POLICY}
                          <span className="text-[16px]">
                            {Privacy_Policy_Content?.PRIVACY_POLICY}
                          </span>
                          {Privacy_Policy_Content?.POLICY_DETAILS}
                          <InputFieldError
                            errors={errors}
                            touched={touched}
                            name="termsAndConditions"
                          />
                        </div>
                      </div> */}
                      <div>
                      <ReCAPTCHA
                          ref={reCaptchaRef}
                          size="normal"
                          sitekey={ReCAPTCHA_sitekey}
                          onChange={(captchaCode) => {
                            setFieldValue("reCaptcha", captchaCode);
                            // onReCAPTCHAChange
                          }}
                        />
                        <InputFieldError
                          errors={errors}
                          touched={touched}
                          name="reCaptcha"
                        />
                      </div>
                      <div className="w-full text-center">
                        
                        <button
                          type="submit"
                          className={`bg-[#3A658E] text-white w-full rounded-md py-3 mt-5 ${isLoading?"cursor-not-allowed":"cursor-pointer"}`}
                          disabled={isLoading}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactUsModel;
