import { Footer_Content } from "../../../utils/text_content";
import LightLogo from "../../../assets/Img/logo-light.png";
import { Link } from "react-router-dom";
import FBIcone from "../../../assets/svg/square-facebook.svg";
const Footer = () => {
  return (
    <>
      <div className="bg-black py-20">
        <div className="custom-container flex flex-col md:flex-row">
          <div className="w-full md:w-[40%]">
            <img src={LightLogo} alt="" className="w-[150px]" />
            <div className="mt-2">
              <Link
                to="/contact"
                className="text-white text-[16px] hover:text-[#EDAC15] fill-white hover:fill-[#EDAC15] flex"
              >
                {/* --------- Svg icon Start----------- */}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-[16px] mr-2"><path d="M0 256C0 397.4 114.6 512 256 512s256-114.6 256-256S397.4 0 256 0S0 114.6 0 256zM297 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L120 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l214.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L409 239c9.4 9.4 9.4 24.6 0 33.9L297 385z"/></svg>
                {/* --------- svg Icon End -------------*/}
                Contact Us
              </Link>
            </div>
          </div>
          <div className="w-full md:w-[60%] text-[#8F8F8F]">
            {Footer_Content?.EQUIPMENT_INDUSTRY}
            <div className="mt-2">
              <a
                target="_blank"
                href="https://www.facebook.com/heavyequipmentco/"
                className="text-[20px] text-[#EDAC15] hover:text-[#8F8F8F] font-bold flex fill-[#EDAC15] hover:fill-[#8F8F8F]"
              >
                {/* --------- Svg icon Start----------- */}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-[20px] ">
                  <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z" />
                </svg>
                {/* --------- svg Icon End -------------*/}
                
                <span className="ml-2">Like Us On Facebook</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
