import Layout from "../../components/layout/layout";
import SubPageTopBanner from "../../components/SubPageTopBanner/SubPageTopBanner";
import HeavyEquipmentInfo from "./heavyEquipmentInfo/heavyEquipmentInfo";
import CompanyOwner from "./CompanyOwner/companyOwner";
import { Our_Company_Content } from "../../utils/text_content";

const OurCompany = () => {
  return (
    <>
      <div className="our_company_page_background">
        <Layout>
          <SubPageTopBanner title={Our_Company_Content?.OUR_COMPANY} />
          <HeavyEquipmentInfo/>
          <CompanyOwner/>
        </Layout>
      </div>
    </>
  );
};
export default OurCompany;
