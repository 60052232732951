import Layout from "../../components/layout/layout";
import Banner from "./banner/banner";
import About from "./about/about";
import Review from "./review/review";
const Home = () => {
  return (
    <>
      <div className="home_page_background">
        <Layout authorized_banner={false}>
            <Banner/>
            <About/>
            <Review/>
        </Layout>
      </div>
    </>
  );
};
export default Home;
