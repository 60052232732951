import Home from "./pages/Home/home";
import OurCompany from "./pages/Our_Company/ourCompany";
import ContactUs from "./pages/Contact_US/contactUS";
import Inventory from "./pages/Inventory/Inventory";
import InventoryDetails from "./pages/Inventory_Details/InventoryDetails";
import "./App.css";
import "../src/style/home.css";
import "../src/style/our_company.css";
import "../src/style/contactUs.css";
import "../src/style/inventoryDetails.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <ToastContainer />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/our-company" element={<OurCompany />}></Route>
          <Route path="/contact" element={<ContactUs />}></Route>
          <Route path="/inventory" element={<Inventory />}></Route>
          <Route
            path="/inventory-details/:id"
            element={<InventoryDetails />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
