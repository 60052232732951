const SubPageTopBanner = ({title}) => {
  return (
    <>
        <div className="h-[300px] flex justify-center items-center">
        <h1 className="uppercase text-[40px] text-white font-bold">{title}</h1>
        </div>
    </>
  );
};
export default SubPageTopBanner;
