import React from 'react'

export default function Drawer({children, isOpen, setIsOpen}) {
  return (
    <main
      className={
        ' fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out ' +
        (isOpen
          ? ' transition-opacity opacity-100 duration-500 -translate-x-0  '
          : ' transition-all delay-500 opacity-0 -translate-x-full  ')
      }
    >
      <section
        className={
          ' w-[250px] max-w-lg left-0 absolute bg-[#fff] h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  ' +
          (isOpen ? '-translate-x-0 ' : ' -translate-x-full ')
        }
      >
        <article className="relative w-[250px] max-w-lg pb-10 flex flex-col space-y-6 overflow-y-scroll h-full">
          <header className="font-bold text-lg">
            {' '}
            <div className="text-end p-4">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="bg-[#a7a6a6] text-[#000] w-8 h-8 font-bold	text-[24px]"
              >
                ×
              </button>
            </div>
          </header>
          {children}
        </article>
      </section>
      <section
        className="w-[250px] h-full cursor-pointer "
        onClick={() => {
          setIsOpen(false)
        }}
      ></section>
    </main>
  )
}
