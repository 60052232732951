import api from "../api/api";
import postApi from "../api/postApi";
import * as url from "../api/url";
import { ApplicationId } from "../utils/text_content";
// Post Machine List
export const getInventory = (payload) => {
  return new Promise(async (resolve, reject) => {
    return postApi
      .post(url.GETINVENTORY , payload)
      .then(response => {
        if (response) {
          resolve(response)
        }
      })
      .catch(err => {
        reject(err.response)
      })
  })
}
 

export const getMachineDetailsByID = (ID) => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.GET_MACHINES_DETAILS + `?assetAdvertisementId=${ID}&applicationId=${ApplicationId}`)
      .then(response => {
        if (response) {
          resolve(response)
        }
      })
      .catch(err => {
        reject(err.response)
      })
  })
}

export const getAllMachinesList =()=>{
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.GET_CATEGORY_LIST + `?applicationId=${ApplicationId}`)
      .then(response => {
        if (response) {
          resolve(response)
        }
      })
      .catch(err => {
        reject(err.response)
      })
  })
}